<template>
    <uw-content title="我的任务" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.status == null ? 'primary' : 'text'" @click="search.status = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.status == 0 ? 'primary' : 'text'" @click="search.status = 0; TableSearch()">未开始</el-button>
            <el-button size="mini" :type="search.status == 1 ? 'primary' : 'text'" @click="search.status = 1; TableSearch()">进行中</el-button>
            <el-button size="mini" :type="search.status == 5 ? 'primary' : 'text'" @click="search.status = 5; TableSearch()">已完成</el-button>
            <el-button size="mini" :type="search.status == 4 ? 'primary' : 'text'" @click="search.status = 4; TableSearch()">已关闭</el-button>
            <el-button size="mini" :type="search.status == 2 ? 'warning' : 'text'" @click="search.status = 2; TableSearch()">已暂停</el-button>
            <el-button size="mini" :type="search.status == 6 ? 'warning' : 'text'" @click="search.status = 6; TableSearch()">已取消</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-dropdown trigger="click">
                <el-button type="primary" size="mini">批量管理<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native.stop="TaskDeleteMultiple()">批量删除</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowStartMultiple()" divided>批量开始</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowSuspendMultiple()">批量暂停</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowCancelMultiple()">批量取消</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowCloseMultiple()">批量关闭</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <!-- 延期状态 -->
                <el-select v-model="search.is_overdue" @change="TableSearch()" placeholder="是否已延期?" size="mini" clearable filterable>
                    <el-option :value="0" label="未延期"></el-option>
                    <el-option :value="1" label="已延期"></el-option>
                </el-select>

                <!-- 任务名称 -->
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.name" clearable  size="mini" placeholder="任务名称"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <files-export-xls-dropdown model="pm_task" :search="search" :style="{ marginLeft: '5px', float: 'right' }" />
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" :data="task" height="100%">

                    <!-- 复选框 -->
                    <vxe-column type="checkbox" width="45" fixed="left"></vxe-column>

                    <!-- 管理项目 -->
                    <vxe-column min-width="240" field="project" title="项目名称">
                        <template #default="{ row }">
                            <el-link @click="$refs.projectsDrawer.Open(row.project.id)" type="primary">{{ row.project.number }}-{{ row.project.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 任务名称 -->
                    <vxe-column min-width="300" title="任务名称">
                        <template #default="{ row }">
                            <!-- 重要程度 -->
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 1" effect="plain" type="danger">P1</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 2" effect="plain" type="warning">P2</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 3" effect="plain" type="primary">P3</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 4" effect="plain" type="success">P4</el-tag>
                            <!-- 状态判断 -->
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 0" effect="dark" type="primary">未开始</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 1" effect="dark" type="primary">进行中</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 2" effect="dark" type="warning">已暂停</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 4" effect="dark" type="info">已关闭</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 5" effect="dark" type="success">已完成</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 6" effect="dark" type="info">已取消</el-tag>
                            <!-- 延期判断 -->
                            <el-tag v-if="row.is_overdue == 1" effect="dark" type="danger">已延期</el-tag>
                            <!-- 项目名称 -->
                            <el-span type="primary" :style="{ color: row.name_color }" @click.native="$refs.taskIndex.Open(row.id)" :title="row.name" />
                        </template>
                    </vxe-column>

                    <!-- 里程碑/项目阶段 -->
                    <vxe-column min-width="150" field="name" title="里程碑/项目阶段">
                        <template #default="{ row }">
                            {{ row.milestone?.name ?? '-' }}
                        </template>
                    </vxe-column>

                    <!-- 任务类型 -->
                    <vxe-column width="120" field="type" title="任务类型" />

                    <!-- 指派给 -->
                    <vxe-column width="110" title="指派给">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.now_assign_user?.avatar"> {{ row.now_assign_user?.name[0] }} </el-avatar>
                                {{  row.now_assign_user?.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 参与人员 -->
                    <vxe-column width="80" field="assign" title="参与人员" :visible="true">
                        <template #default="{ row }">
                            <el-tooltip placement="top" :content="row.assign_user_name.toString()">
                                <el-link icon="el-icon-user">{{ row.assign.length }}</el-link>
                            </el-tooltip>
                        </template>
                    </vxe-column>

                    <!-- 概要 -->
                    <vxe-column min-width="90" field="day_predict" title="任务预期/P"></vxe-column>
                    <vxe-column min-width="90" field="day_consume" title="任务消耗/P"></vxe-column>

                    <!-- 时间 -->
                    <vxe-column width="90" field="start_date[0]" title="开始时间"></vxe-column>
                    <vxe-column width="90" field="start_date[1]" title="截止时间"></vxe-column>
                </vxe-table>

            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <pm-projects-drawer ref="projectsDrawer" @onChange="TableSearch" />
        <pm-task-create ref="taskCreate" @onSave="TableSearch()" />
        <pm-task-update ref="taskEdit" @onSave="TableSearch()" />
        <pm-task-drawer ref="taskIndex" @onChange="TableSearch()" />

        <!-- 任务创建 -->
        <taskCreateMultiple ref="taskCreateMultiple" @onSave="TableSearch()" />
        
    </uw-content>
</template>

<script>
import taskCreateMultiple from '@/views/projects/drawerProjectsTaskCreateMultiple'

export default {
    components: {
        taskCreateMultiple,
    },

    props: {
        id: {
            type: Number,
            require: true
        }
    },

    data () {
        return {
            loading: true,

            my: {},
            task: [],

            // 分页
            page: {
                total: 0,
                size: 20,
                current: 1,
            },

            // 检索
            search: {
                assign: [],
                status: null,
                is_overdue: null,
                name: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },
    async mounted () {
        this.$http.post('/9api/my/info').then(rps => {
            this.my = rps.data
            this.search.assign = [rps.data.id]
            this.TableSearch()
        })
    },

    methods: {

        /**
        |--------------------------------------------------
        | 项目任务 - 检索
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 切换加载状态
            this.loading = true

            // 检索请求
            const append = ['now_assign_user', 'milestone', 'project', 'type', 'assign_user_object', 'assign_user_name', 'now_assign_user_name' ]
            const hidden = ['describe']
            this.$http.post('/9api/pm/task/search', { page: this.page, search: this.search, order: this.order, append: append, hidden: hidden }).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.task = rps.data.body

                // 页码溢出
                if (this.task.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 项目任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDelete(ids) {

            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 任务工时、任务附件、子任务、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/projects-task/delete', { ids: ids }).then(rps => {
                    this.TableSearch()
                })
            })
        },

        TaskDeleteMultiple() {
            this.TaskDelete(this.$refs.xTable.getCheckboxRecords().map(item => item.id))
        },

        /**
        |--------------------------------------------------
        | 批量操作
        |--------------------------------------------------
        |
        */

        TaskFlowStartMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-start-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowSuspendMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-suspend-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowCancelMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-cancel-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowCloseMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-close-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>
